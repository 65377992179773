export default () => ({
  toasts: [],

  add(event) {
    this.toasts.push({
      id: `toast-${Math.random().toString(16).slice(2)}`,
      type: event.detail.type,
      message: event.detail.message,
    });
  },

  show(id) {
    const toast = this.toasts.find((t) => t.id === id);
    const index = this.toasts.findIndex((t) => t.id === id);
    this.toasts.splice(index, 1, { ...toast, show: true });
  },

  remove(id) {
    const toast = this.toasts.find((t) => t.id === id);
    const index = this.toasts.findIndex((t) => t.id === id);
    this.toasts.splice(index, 1, { ...toast, show: false });
  },

  toastInit(el) {
    const id = el.getAttribute("id");
    let that = this;

    // enter state
    setTimeout(() => {
      that.show(id);
    }, 50);

    // leave
    setTimeout(() => {
      that.remove(id);
    }, 4000);
  },

  globalInit() {
    window.toast = function (message, type = "info") {
      window.dispatchEvent(
          new CustomEvent("add-toast", {
            detail: { message, type },
          }),
      );
    };
  },

})

