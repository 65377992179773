import Alpine from "alpinejs";
import ui from "@alpinejs/ui";
import mask from "@alpinejs/mask";
import focus from "@alpinejs/focus";
import persist from "@alpinejs/persist";
import money from "alpinejs-money";

import toastComponent from "./alpine/toast.js";

Alpine.plugin([mask, focus, persist, ui, money]);
Alpine.data("toast", toastComponent);

window.Alpine = Alpine;

Alpine.start();

// event listener for toast
document.body.addEventListener("toast", function (evt) {
  window.toast(evt.detail.message, evt.detail.level);
});
